<!--能源模块--条件组件-->
<template>
  <div v-loading="frmloading" :element-loading-text="$t('alarm.a0')">
    <a-form-model 
      ref="groupModal"
      :model="form" 
      :rules="rules"
      :label-col="formItemLayout.labelCol" 
      :wrapper-col="formItemLayout.wrapperCol"  >
          
      <a-form-model-item  ref="name" prop="name">
          <span slot="label">
            {{labelInput}}&nbsp;
            <a-tooltip :title='tooltip'>
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input 
              v-model="name"
              @blur="
              () => {
                  $refs.name.onFieldBlur();
              }
              "
              :placeholder="inputLabel"
              @input="change"
          />
      </a-form-model-item>    
      <a-form-model-item  ref="datePicker" prop="effectivePeriod">
          <span slot="label">
            {{labelDatePicker}}&nbsp;
          </span>
          <a-range-picker v-model="effectivePeriod" :format="dateFormat" @change="change"/>
      </a-form-model-item>                
    </a-form-model>
  </div>       
</template>

<script>
import moment from "moment";
export default {
  components: {
  },
  model:{
    prop:'data',
    event:'change',
  },
  props: {
    data:Object,
  },
  data() {      
      let tooltip=this.$t("energy.setting.enePeakValleySet.stop")+' [ ` ~ ! @ # $ % ^ & *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】';
      let inputLabel=this.$t("energy.setting.enePeakValleySet.inputLabel");   
      let inputPeriod=this.$t("energy.setting.enePeakValleySet.inputPeriod"); 
      let name="";
      switch(this.type){
        case 0:
          if(this.isAdd)
            name= this.$t("energy.setting.enePeakValleySet.groupName");
          break;
        case 1:
          if(this.isAdd)
            name= this.$t("energy.setting.enePeakValleySet.subGroupName");
          else
            name = this.$t("energy.setting.enePeakValleySet.groupName");
          break;
        case 2:
          if(this.isAdd)
            name= this.$t("energy.setting.enePeakValleySet.periodOfTimeName");
          else
            name = this.$t("energy.setting.enePeakValleySet.subGroupName");
          break;
      }      
      inputLabel = inputLabel.replace('${label}',name);
      let lenLabel=this.$t("energy.setting.enePeakValleySet.lenLabel").replace('${label}',name).replace('${min}',1).replace('${max}',30);
      let spLabel=this.$t("energy.setting.enePeakValleySet.spLabel").replace('${label}',name);
      
    return {   
      dateFormat : 'YYYY/MM/DD',
      labelInput:"名称",
      labelDatePicker:"生效周期",
      name:"",
      effectivePeriod:[
        new moment(moment().startOf('year').format("YYYY-MM-DD")),
        new moment(moment().endOf('year').format("YYYY-MM-DD")),
      ],
      form:{},   
      tooltip:tooltip,
      inputLabel:inputLabel,
      inputPeriod:inputPeriod,
      lenLabel:lenLabel,
      spLabel:spLabel,
      frmloading:false,
      formItemLayout:{
        labelCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      },
      rules: {
        name: [
          { required: true, message: inputLabel, trigger: 'blur' },
          { min: 1, max: 30, message: lenLabel, trigger: 'blur' },
          { validator:this.normalName, trigger: 'blur' },
        ],
        effectivePeriod:[
          { required: true, message: inputPeriod, trigger: 'change' },
          { validator:this.normalEffectivePeriod, trigger: 'change' },
        ]
      },
    };    
  },
  methods: {    
    change(){
      if(this.effectivePeriod!=null&&this.effectivePeriod.length>0){
        this.form.periodOfTime = this.effectivePeriod[0].format('YYYY/MM/DD')+"~"+this.effectivePeriod[1].format('YYYY/MM/DD');
      }
      this.form.name=this.name;
      this.$emit("change", this.form);
    },
    normalName(rule, value, callback) {
      let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
      if(regEn.test(value)) {
          callback(new Error(spLabel));
      } else {
          callback();
      }
    },
    normalEffectivePeriod(rule,value,callback){
      if(!value&&this.form.periodOfTime=="") {
        callback(new Error(spLabel));
        return false;
      } else {
        callback();
      }
    },
  },
  computed: {
    pluginsDataChange () {
      const dataStr=JSON.stringify(this.data);   
      // this.linkageConditionEdit = JSON.parse(dataStr);
      console.log("addGroupModal computed data:",this.data);    
      this.form=this.data;    
      if(this.form!=null){
        this.name= this.form.name;
        if(this.form.periodOfTime!=null&&this.form.periodOfTime!=""){
          let periods=this.form.periodOfTime.split("~");
          this.effectivePeriod.length=0;//2022-11-17 保留了旧的，界面显示旧的
          this.effectivePeriod.push(new moment(periods[0]));
          this.effectivePeriod.push(new moment(periods[1]));
        }
      }
    }, 
       

  },
  watch:{
    pluginsDataChange: function (val){
      // this.form = JSON.parse(val);
      // console.log("subsection watch form:",this.form);
    },
  }
};
</script>

<style scoped>
</style>