<!--能源模块--条件组件-->
<template>
  <div>
    <a-form-model layout="inline" :model="form">
      <a-form-model-item label="名称" :wrapper-col="wrapperCol">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="时间段" :wrapper-col="wrapperColPeriodOfTime">
        <a-select 
          mode="multiple" 
          :max-tag-count="maxTagCount"
          v-model="selectValues"
          :options="selectOptions"
          @change="handleChange"
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="能耗单价" :wrapper-col="wrapperCol">
        <a-input-number :min="0" :max="9999"v-model="form.pricePerUnit" />
      </a-form-model-item>
      <a-form-model-item label="单价单位" :wrapper-col="wrapperCol">
        <a-select v-model="form.unit" >
          <a-select-option v-for="item in unitOptions" :key="item.key" :value="item.code">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="颜色" :wrapper-col="wrapperCol">
        <!-- <a-select v-model="form.color" >
          <a-select-option v-for="item in colorOptions" :key="item.key" :value="item.code">
            {{ item.title }}
          </a-select-option>
        </a-select> -->
        <a-tag :color="form.color" @click="clickTag">{{form.color}}</a-tag>
      </a-form-model-item>
      <!-- <a-form-model-item  :wrapper-col="buttonItemLayout.wrapperCol">
        <a-button class="buttonSet" type="primary" @click="save">
          保存
        </a-button>
      </a-form-model-item> -->
      <a-form-model-item v-if="!form.isPermanent" :wrapper-col="buttonItemLayout.wrapperCol">
        <a-button class="buttonDelete" type="primary" @click="deletePeriodOfTime">
          删除
        </a-button>
      </a-form-model-item>
    </a-form-model>  
    <a-modal v-model="visible" :footer="null">
      <div>
        <a-tag class="tagColor" v-for="item in colorOptions" :key="item.key" :color="item.code"  @click="choose(item.code)">{{item.code}}</a-tag>
      </div>
    </a-modal>
  </div>  
</template>

<script>

import moment from "moment";

export default {
  components: {
  },

  props: {
    data:Object,
    options:Array ,
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      if (value && value > 99999) {
        callback(new Error('不能超过99999'))
      }
      const regex = /^[0-9]*$/;
      if (value && !regex.test(value)){
        callback("只能为数字")
      }
      callback()
    }
    return { 
      wrapperCol: { style: { width: '120px' } },
      wrapperColPeriodOfTime: { style: { width: '210px' } },
      title:"颜色选择",
      visible:false,
      maxTagCount:1,
      selectValues:[],
      selectOptions:[{key:1,value:"0:00~1:00",title:"0:00~1:00"},
        {key:2,value:"1:00~2:00",title:"1:00~2:00"},
        {key:3,value:"2:00~3:00",title:"2:00~3:00"},
        {key:4,value:"3:00~4:00",title:"3:00~4:00"},
        {key:5,value:"4:00~5:00",title:"4:00~5:00"},
        {key:6,value:"5:00~6:00",title:"5:00~6:00"},
        {key:7,value:"6:00~7:00",title:"6:00~7:00"},
        {key:8,value:"7:00~8:00",title:"7:00~8:00"},
        {key:9,value:"8:00~9:00",title:"8:00~9:00"},
        {key:10,value:"9:00~10:00",title:"9:00~10:00"},
        {key:11,value:"10:00~11:00",title:"10:00~11:00"},
        {key:12,value:"11:00~12:00",title:"11:00~12:00"},
        {key:13,value:"12:00~13:00",title:"12:00~13:00"},
        {key:14,value:"13:00~14:00",title:"13:00~14:00"},
        {key:15,value:"14:00~15:00",title:"14:00~15:00"},
        {key:16,value:"15:00~16:00",title:"15:00~16:00"},
        {key:17,value:"16:00~17:00",title:"16:00~17:00"},
        {key:18,value:"17:00~18:00",title:"17:00~18:00"},
        {key:19,value:"18:00~19:00",title:"18:00~19:00"},
        {key:20,value:"19:00~20:00",title:"19:00~20:00"},
        {key:21,value:"20:00~21:00",title:"20:00~21:00"},
        {key:22,value:"21:00~22:00",title:"21:00~22:00"},
        {key:23,value:"22:00~23:00",title:"22:00~23:00"},
        {key:24,value:"23:00~24:00",title:"23:00~24:00"},],//供选择选项
      unitOptions:[
        {key:1,code:"cent",title:"分/kWh"},
        {key:2,code:"10cents",title:"角/kWh"},
        {key:3,code:"yuan",title:"元/m3"}
      ],
      colorOptions:[
        {key:1,code:"#e65a56",title:"颜色1"},
        {key:2,code:"#ff974c",title:"颜色2"},
        {key:3,code:"#5aaef3",title:"颜色3"},
        {key:4,code:"#62d9ad",title:"颜色4"},
        {key:5,code:"#b3b3ff",title:"颜色5"},
        {key:6,code:"#0066ff",title:"颜色6"},
        {key:7,code:"#00e6e6",title:"颜色7"},
      ],
      form:{color:"#e65a56"},
      layout:"inline",
    };    
  },
  methods: {   
    choose(e){
      this.form.color = e;
      this.visible=false;
    },
    clickTag(){
      this.visible=true;
    },
    handleChange(e){
      if(e!=null){        
        this.form.periodOfTime=e.join(",");
        console.log("subsection handleChange form:",this.form); 
      }
    },
    save(){
      this.$emit("savePeriodOfTime", this.form);
    },

    deletePeriodOfTime(){
      this.$emit("deletePeriodOfTime", this.form);
    },
  },
  computed: {
    pluginsDataChange () {
      const dataStr=JSON.stringify(this.data);              
      this.form=this.data;

      //取选项副本
      // this.selectOptions=this.options.slice(0);
      this.selectOptions=[...this.options];
      console.log("subsection pluginsDataChange form:",this.form);
      if(this.form!=null&&this.form.periodOfTime!=""){
        this.selectValues=this.form.periodOfTime.split(",");

      console.log("subsection pluginsDataChange selectValues:",this.selectValues);
      console.log("subsection pluginsDataChange options:",this.options);
        //合并数组
        let selectItem=null;
        let key="";
        if(this.selectValues!=null&&this.selectValues.length>0){
          for(let i=0;i<this.selectValues.length;i++){
            key=this.selectValues[i].substring(0,this.selectValues[i].indexOf(":"));
            selectItem={
              key:parseInt(key),                        
              title:this.selectValues[i],
              value:this.selectValues[i],
            }
            this.selectOptions.push(selectItem);
          }
        }
      }      

      let data = JSON.parse(dataStr);
      return dataStr;
    },
    formItemLayout() {
      return this.layout === 'horizontal'
        ? {
            labelCol: { span: 4, offset:1 },
            wrapperCol: { span: 14 },
          }
        : {
            // labelCol: { span: 11, offset:1 },
            wrapperCol: { style: { width: '120px' } },
          };
    },
    buttonItemLayout() {
      const { layout } = this.form;
      return layout === 'horizontal'
        ? {
            labelCol: { span: 0, offset:1 },
            wrapperCol: { span: 14, offset: 4 },
          }
        : {};
    },

  },
  watch:{
    pluginsDataChange: function (val){
      // console.log("subsection watch selectValues:",val); 
      // if(val!=null&&val.length>0)
      //   this.form.periodOfTime = val.join(",");
      // console.log("subsection watch form:",this.form);
    },
  }
};
</script>

<style scoped>
#subsection {
  /* display: flex;
  justify-content: left; */
  margin: 10px 0px;
}
.buttonSet{
  background-color: rgb(145, 65, 196);
}
.buttonDelete{
  background-color: red;
}
.select{
  width: 300;
}
.tagColor{
  margin-top: 20px;
}
</style>