<!--电能设置--能源峰谷设置---->
<template>
  <div id="enePeakValleySet" v-loading="loading" :element-loading-text="$t('alarm.a0')">     
    <div style="display: flex;width:100%;height:100%;">
      <!-- 左侧菜单栏 -->
      <div class="side">        
        <div class="mg">   
          <div class="header-title">
            <span>{{title}}</span> 
          </div>       
          <div class="mg-add">            
            <a-icon type="plus-circle" style="vertical-align:10px;" @click="addClick" :title="addTitle"/>
          </div> 
        </div>
        <div class="side-menu">          
          <a-menu 
            mode="inline"
            :inline-collapsed="collapsed"
            :open-keys.sync="openKeys"
            @click="handleClick"
          >
            <template v-for="(item,index) in peakValleyCompositList">
              <a-menu-item  :key="index">                
                <a-row>
                  <a-col :span="20">
                    <a-icon type="snippets" />
                    <span>{{ item.name }}</span>
                  </a-col>
                  <a-col :span="4">
                    <a-icon type="delete" @click="deleteClick(item,index)" /> 
                  </a-col>
                </a-row>                                 
              </a-menu-item>              
              <!-- <sub-menu v-else :key="item.no" :menu-info="item" :fatherMethod="deleteClick"/> -->
            </template>            
          </a-menu>
        </div>        
      </div>
      <!-- 主页面，未选择菜单时显示空页面 -->
      <div class="main" ref="main" v-if="selectKey==-1">
          <a-empty>
            <span slot="description"> {{ $t("energy.setting.enePeakValleySet.empty") }} </span>
          </a-empty>
      </div>
      <!-- 主页面 -->
      <div class="main" ref="main" v-else>    
        <div class="peakValleyGroup">  
          <div class="peakValleyEdit"  style="">
            <div class="peakValleyName" style="">
              <span>{{ $t("energy.setting.enePeakValleySet.groupName") }}：</span>
              <span>{{selectPeakValleyName}}</span>
            </div>
            <!-- 编辑分段按钮图标 -->
            <a-button  class="buttonIcon" shape="circle" icon="edit" size="small"  @click="updateClick" :disabled="selectKey==-1"  />
            <!-- 添加 -->
            <a-button  class="buttonAddSub" type="primary" @click="addSubClick" :disabled="selectKey==-1"> {{ $t("energy.setting.eneLinkage.add") }} </a-button>
            <!-- 是否启用 -->
            <a-select class="selectEnable" v-model="selectPeakValleyIsEnable" @change="enableStatusChange" >
              <a-select-option v-for="item in optionsEnable" :key="item.key" :value="item.value">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </div>
          <!-- 子分段 -->
          <div class="peakValleySub">
            <peakValleySub 
              v-for="(item, index) in peakValleySubList" 
              :data="item" 
              :key="index"
              @addSub="addSub"
              @updateSub="updateSub" 
              @deleteSub="deleteSub" 
              @savePeriodOfTime="savePeriodOfTime"
              @saveAllPeriodOfTime="saveAllPeriodOfTime"
              @deletePeriodOfTime="deletePeriodOfTime"
              >
              </peakValleySub>
          </div>         
        </div>        
      </div>
      <div>
        <a-modal v-model="visible" :title="title" @ok="handleOk" :width='600'>
          <addEditModal :label="addEditModalName" v-model="form.name" :type="type" :isAdd="isAdd"></addEditModal>
        </a-modal> 
        <a-modal v-model="visibleGroup" :title="titleGroup" @ok="handleOkGroup" :width='400'>
          <addGroupModal v-model="form"></addGroupModal>
        </a-modal>      
      </div>      
    </div>
  </div>
</template>

<script>
import { getPeakValleyCompositBySiteId,savePeakValley,savePeakValleyList,updatePeakValleyList,deletePeakValley,addPeakValley } from "../../../api/energy";
import peakValleySub from "../components/peakValley/peakValleySub.vue";
import moment from "moment";
import addEditModal from "../components/peakValley/addEditModal.vue";
import addGroupModal from "../components/peakValley/addGroupModal.vue"

const formItemLayout = {
  labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
  wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
  },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

export default {
  components: {
    "addEditModal":addEditModal,
    'peakValleySub':peakValleySub,
    'addGroupModal':addGroupModal,
  },
  data() {
      let name = this.$t("energy.setting.enePeakValleySet.title");
      let addTitle  = this.$t("energy.add") + name;
      let spLabel = this.$t("energy.setting.reportGr.spLabel").replace('${label}',name);
      let lenLabel = this.$t("energy.setting.reportGr.lenLabel").replace('${label}',name).replace('${min}',1).replace('${max}',30);
      let inputLabel = this.$t("energy.setting.reportGr.inputLabel");
      console.log('inputLabel>>',inputLabel);
      inputLabel = inputLabel.replace('${label}',name);
      console.log('inputLabel2>>',inputLabel);
      let tooltip =this.$t("energy.setting.reportGr.stop")+' [ ` ~ ! @ # $ % ^ & *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】';
      let normalName = (rule, value, callback) => {
          let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
          if(regEn.test(value)) {
              callback(new Error(spLabel));
          } else {
              callback();
          }
      };
    
      return {
        title:this.$t("energy.setting.enePeakValleySet.title"),
        titleGroup:"分组编辑",
        addEditModalName:this.$t("energy.setting.enePeakValleySet.groupName"),
        type:2,
        isAdd:false, 
        loading:false,
        frmloading:false,
        peakValleyCompositList:[],
        selectKey:-1,
        selectPeakValleyName:"",
        selectPeakValleyIsEnable:"false",
        selectPeakValley:{},     
        peakValleySubList:[],
        form:{
            id:0,//ID
            name: "",//名称
            siteId: this.$route.query.id,  //站点
            periodOfTime:"",//时间段                      
            type: 0,//类型（0：峰谷；1：分组；2：时间段）
            parentId:0,//上级
            isEnable:false,//是否启用
            isPermanent:false, //是否永久（不可删除）             
            lastUpdated:new moment().valueOf(),//最后更新时间 
        },
        optionsEnable:[
          {key:1,value:"true",title:"启用"},
          {key:2,value:"false",title:"不启用"},
        ],
        result:false,
        Yscroll:{y:400},
        rootSubmenuKeys: ["0"],
        openKeys: ["0"],
        visible:false,
        visibleGroup:false,
        dataChange:false,
        selChange:false,
        collapsed: false,
        formItemLayout,
        formTailLayout,
        rules: {
            name: [
                { required: true, message: inputLabel, trigger: 'blur' },
                { min: 1, max: 30, message: lenLabel, trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ]
        },
        pointSelectVisible:false,
        pointSelectTitle:this.$t("energy.setting.reportGr.timeTrendGr.edit"),
        modeno:20,
        lmKind:3,
        reportGroupType:5,
        addTitle:addTitle,
        tooltip:tooltip,
        inputLabel:inputLabel,
        spLabel:spLabel,
        lenLabel:lenLabel,
      };
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    //取联动控制规则组
    this.getPeakValleyComposit();
  },
  computed:{
    pluginsDataChange () {
      const dataStr=JSON.stringify(this.peakValleyCompositList);        
      return dataStr;
    }
  },
  mounted() { 

  },
  beforeDestroy() {

  },
  watch: {
    pluginsDataChange: function (val){
      this.refreshSelectPeakValley();
    },
  },
  methods: {
    refreshSelectPeakValley(){
      if(this.peakValleyCompositList!=null&&this.peakValleyCompositList.length>0){
        if(this.selectKey>=0){
          this.selectPeakValley=this.peakValleyCompositList[this.selectKey];
          if(this.selectPeakValley!=null){
            this.selectPeakValleyName=this.selectPeakValley.name;
            this.selectPeakValleyIsEnable = this.selectPeakValley.isEnable?"true":"false";
            this.peakValleySubList = this.selectPeakValley.children;
          }
        }
      }
    },
    enableStatusChange(e){
      console.log("enableStatusChange e",e);
      if(e=="true"){
        let peakValleyList=[];
        this.selectPeakValley.isEnable = e=="true";
        peakValleyList.push(this.selectPeakValley);
        //只能设置一项为启用，其他设置为不启用
        for(let i=0;i<this.peakValleyCompositList.length;i++){
          if(this.peakValleyCompositList[i].id!=this.selectPeakValley.id&&this.peakValleyCompositList[i].isEnable){
            this.peakValleyCompositList[i].isEnable=false;
            peakValleyList.push(this.peakValleyCompositList[i]);
          }
        }
        this.updateItemList(peakValleyList);
      }
      else{        
        this.updateItem(this.selectPeakValley);
      }
    },
    getPeakValleyComposit(){        
      let params =  {
          siteId: this.$route.query.id,
      };
      console.log("getPeakValleyComposit Params",params);
      this.loading = true;
      getPeakValleyCompositBySiteId(params)
      .then((res) => {
          console.log("getPeakValleyComposit Result",res);
          this.loading = false;
          if(res && res.errorCode == '00'){
              this.peakValleyCompositList = res.data;
          } else {
              this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
          }

      })
      .catch((err) => {
          this.loading = false;
          console.log(err);
      });
    },       
    handleClick(e) {
        console.log('handleClick', e);
        this.selectKey = parseInt(e.key);
        this.refreshSelectPeakValley();
        // if(this.peakValleyCompositList!=null&&this.peakValleyCompositList.length>0){
        //   this.selectPeakValley=this.peakValleyCompositList[this.selectKey];
        //   this.selectPeakValleyName=this.selectPeakValley.name;
        //   this.selectPeakValleyIsEnable = this.selectPeakValley.isEnable?"true":"false";
        // }
    },
    deleteClick(item,index) {
        console.log('deleteClick', item,index);
        let title = this.$t("energy.setting.enePeakValleySet.deleLabel").replace('${name}',item.name);
        this.$confirm({
        title: title,
        centered: true,
        onOk: () => {
            this.loading = true;
            console.log('deletePeakValley',item);            
            this.deleteAndRefreshUI(item);
            }
        });
    },
    async deleteAndRefreshUI(params){
      await this.deletePeakValley(params);     
          
          this.selectKey = -1;
          this.refreshSelectPeakValley();
    },
    async deletePeakValley(params){
      deletePeakValley(params)
        .then((res) => {
          console.log("deletePeakValley Result",res);
        
          if(res.errorCode == '00'){
            this.$message.success(res.msg); 
            //重新加载数据（刷新） 
            this.getPeakValleyComposit();                
          }else{
            this.$message.error(res.msg);                  
          }  
          this.loading = false;    
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });   
    },
    //添加分段设置
    handleOk(e) {
      console.log("handleOk this.form",this.form); 
      let params = {
            id:this.form.id,//数据库记录ID，若设置为0，则为新增
            siteId: this.form.siteId, //站点ID
            name: this.form.name,     //名称
            periodOfTime:this.form.periodOfTime,//时间段                      
            type: this.form.type,//类型（0：峰谷；1：分组；2：时间段）
            parentId:this.form.parentId,//上级
            isEnable:this.form.isEnable,//是否启用
            isPermanent:this.form.isPermanent, //是否永久（不可删除）             
            lastUpdated:new moment().valueOf(),//最后更新时间              
        }
      this.saveAndRefreshUI(params);       
    },
    //添加分组
    handleOkGroup(e){
      if(this.form.name==""||this.form.periodOfTime==""){
        // this.$message.error(this.$t("energy.setting.enePeakValleySet.conflict"));
        return;
      }
      let param = {
            id:this.form.id,//数据库记录ID，若设置为0，则为新增
            siteId: this.form.siteId, //站点ID
            name: this.form.name,     //名称
            periodOfTime:this.form.periodOfTime,//时间段                     
            type: this.form.type,//类型（0：峰谷；1：分组；2：时间段）
            parentId:this.form.parentId,//上级
            isEnable:this.form.isEnable,//是否启用
            isPermanent:this.form.isPermanent, //是否永久（不可删除）             
            lastUpdated:new moment().valueOf(),//最后更新时间              
        }  
      //判断时间段是否有冲突2022-8-18
      let existPeriodOfTimes;
      let existPeriodOfTime;
      let existBegin;
      let existEnd;
      let periodOfTimes=this.form.periodOfTime.split("~");
      let begin=new moment(periodOfTimes[0]);
      let end=new moment(periodOfTimes[1]);
      let isPeriodOfTimeExist=false;
      for(let i=0;i<this.peakValleySubList.length;i++){
        existPeriodOfTime=this.peakValleySubList[i].periodOfTime;
        existPeriodOfTimes=existPeriodOfTime.split("~");
        existBegin=new moment(existPeriodOfTimes[0]);
        existEnd=new moment(existPeriodOfTimes[1]);
        if((begin.valueOf()<existEnd.valueOf()&&begin.valueOf()>=existBegin.valueOf())
        ||(end.valueOf()<=existEnd.valueOf()&&end.valueOf()>existBegin.valueOf())){
          isPeriodOfTimeExist=true;
          break;
        }
      }
      if(isPeriodOfTimeExist){
        this.$message.error(this.$t("energy.setting.enePeakValleySet.conflict"));
        return;
      }
      this.saveWithChildren(param); 
    },
    addSub(e){
      this.addItem(e);
    },
    updateSub(e){
      // this.saveAndRefreshUI(e); //更新并刷新
      this.updateItem(e); //更新但不刷新

    },
    deleteSub(e){
      this.deletePeakValley(e);
    },
    //保存子分段所有时段
    saveAllPeriodOfTime(e){
      // console.log("enePeakValleySet saveAllPeriodOfTime par",e);
      this.updateItemList(e);
    },
    //保存子分段一个时段
    savePeriodOfTime(e){
      this.updateItem(e);
    },
    deletePeriodOfTime(e){
      this.deletePeakValley(e);
    },
    //保存并刷新界面
    async saveAndRefreshUI(params){
      console.log("saveAndRefreshUI params",params);
      let success=false;
      await savePeakValley(params)
        .then((res) => {
            this.frmloading = false;
            if(res && (res.errorCode == '00' || res.errorCode == '03')){ 
              console.log("updateItem res",res);                 
                this.visible = false; 
                this.visibleGroup = false;
                success=true;
                this.$message.success(this.$t("general.a6"));
            }   
            else
              this.$message.error(this.$t("general.a7"));     
        })
        .catch((err) => {
            this.frmloading = false;
            console.log(err);
            this.$message.error(this.$t("general.a7"));
        });
        if(success)
          this.getPeakValleyComposit();
    },  
    //保存列表并刷新界面
    async saveWithChildren(param){
      console.log("saveWithChildren param",param);
      let success=false;
      let id=0;
      await addPeakValley(param)
        .then((res) => {
            this.frmloading = false;
            if(res && (res.errorCode == '00' || res.errorCode == '03')){ 
              console.log("saveWithChildren res",res);                 
                this.visible = false; 
                success=true;
                id=res.data;
                // this.$message.success(this.$t("general.a6"));
            }   
            else
              this.$message.error(this.$t("general.a7"));     
        })
        .catch((err) => {
            this.frmloading = false;
            console.log(err);
            this.$message.error(this.$t("general.a7"));
        });
        if(success){
          let paramChildren = {
            id:0,//数据库记录ID，若设置为0，则为新增
            siteId: param.siteId, //站点ID
            name: "未分段",     //名称
            periodOfTime:"",//"0:00~1:00,1:00~2:00,2:00~3:00,3:00~4:00,4:00~5:00,5:00~6:00,6:00~7:00,7:00~8:00,8:00~9:00,9:00~10:00,10:00~11:00,11:00~12:00,12:00~13:00,13:00~14:00,14:00~15:00,15:00~16:00,16:00~17:00,17:00~18:00,18:00~19:00,19:00~20:00,20:00~21:00,21:00~22:00,22:00~23:00,23:00~24:00",//时间段                     
            type: 2,//类型（0：峰谷；1：分组；2：时间段）
            parentId:id,//上级
            isEnable:false,//是否启用
            isPermanent:true, //是否永久（不可删除）             
            lastUpdated:new moment().valueOf(),//最后更新时间  
            color:"#c3c3c3"            
          } 
          console.log("saveWithChildren paramChildren",paramChildren); 
          this.saveAndRefreshUI(paramChildren);  
        }
    },   
    addItem(params){
        console.log("addItem params",params);
        this.result = false;
        addPeakValley(params)
        .then((res) => {
            this.frmloading = false;
            if(res && (res.errorCode == '00' || res.errorCode == '03')){ 
              console.log("addItem res",res);                 
              params.id=res.data;
              this.$message.success(this.$t("general.a6"));
              //在界面上显示新加项
              this.addToPeakValleySubList(params);              
            }   
            else
              this.$message.error(this.$t("general.a7"));     
        })
        .catch((err) => {
            this.frmloading = false;
            console.log(err);
            this.$message.error(this.$t("general.a7"));
        });
    }, 
    
    addToPeakValleySubList(params){
      let peakValleySub = null;
      for(let i=0;i<this.peakValleySubList.length;i++){
        if(this.peakValleySubList[i].id== params.parentId){
          peakValleySub=this.peakValleySubList[i]; 
          break;
        }
      }
      if(peakValleySub!=null)
        console.log("addToPeakValleySubList peakValleySub",peakValleySub);
        peakValleySub.children.push(params);
    },
    
    updateItem(params){
        console.log("updateItem params",params);
        this.result = false;
        savePeakValley(params)
        .then((res) => {
            this.frmloading = false;
            if(res && (res.errorCode == '00' || res.errorCode == '03')){ 
              console.log("updateItem res",res);                 
                this.visible = false; 
                this.$message.success(this.$t("general.a6"));
            }   
            else
              this.$message.error(this.$t("general.a7"));     
        })
        .catch((err) => {
            this.frmloading = false;
            console.log(err);
            this.$message.error(this.$t("general.a7"));
        });
    },
    updateItemList(params){
        console.log("updateItemList params",params);
        this.result = false;
        // savePeakValleyList(params)
        updatePeakValleyList(params)
        .then((res) => {
            this.frmloading = false;
            if(res && (res.errorCode == '00' || res.errorCode == '03')){ 
              console.log("updateItemList res",res);                 
                this.visible = false; 
                this.$message.success(this.$t("general.a6"));
                //重新加载数据（刷新）
                this.getPeakValleyComposit();
            }   
            else
              this.$message.error(this.$t("general.a7"));     
        })
        .catch((err) => {
            this.frmloading = false;
            console.log(err);
            this.$message.error(this.$t("general.a7"));
        });
    },
    addClick(e) {
      console.log('addClick', e);
      this.form={
            id:0,//数据库记录ID，若设置为0，则为新增
            name: "",//名称
            siteId: this.$route.query.id,  //站点
            periodOfTime:"",//时间段                      
            type: 0,//类型（0：峰谷；1：分组；2：时间段）
            parentId:0,//上级
            isEnable:false,//是否启用
            isPermanent:false, //是否永久（不可删除）             
            lastUpdated:new moment().valueOf(),//最后更新时间 
        }; 
      this.addEditModalName=this.$t("energy.setting.enePeakValleySet.groupName"),
      this.type=0,
      this.isAdd=true,                
      this.visible = true;      
    },
    addSubClick(e){
      console.log('addSubClick', e);
      this.form={
            id:0,//数据库记录ID，若设置为0，则为新增
            name: "",//名称
            siteId: this.$route.query.id,  //站点
            periodOfTime:"",//时间段                      
            type: 1,//类型（0：分段；1：子分段；2：时间段）
            parentId:this.selectPeakValley.id,//上级ID
            isEnable:false,//是否启用
            isPermanent:false, //是否永久（不可删除）             
            lastUpdated:new moment().valueOf(),//最后更新时间 
        };             
      this.visibleGroup = true;
    }, 
    updateClick() {
      console.log('updateClick', this.form);
      this.form=this.selectPeakValley;
      this.visible = true;
    },      
  },
};
</script>

<style scoped>
#enePeakValleySet {
  width: 100%;
  height: 100%;
  padding: 20px;  
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
ul, li {
  list-style: none;
  margin: 0;
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 22px;
  background: #7585cd;
  margin-right: 20px;
}
.side {
  width: 280px;
  height: calc(100% - 24px);
  box-shadow: 0px 3px 8px #e7e6e6;
  position: relative;
}
.side-menu{
  max-height:  calc(100% - 50px);
  overflow: auto;
}
side-menu-item{
  display: flex;
  justify-content: space-between;
}
.main {
  width: 100%;
  height: calc(100% - 24px);
  padding: 5px;
  box-shadow: 0px 3px 8px #e7e6e6;
}
.mg{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.mg-add{
  width: 24px;
  height: 24px;
  border-radius: 24px;
  font-size: 22px;
  color: #7682ce;
  border:1px solid #FFFFFF;
  margin: 3px;
}
.mg-add:hover{
  color: #FFFFFF;
  background-color: #7682ce;
  border:1px solid #7682ce;
 
}
.peakValleyGroup{
  width: 100%;
  height: 100%;
  overflow-y:scroll;
  }
.peakValleyEdit{
  display: flex;   
  justify-content: flex-start;
  }
.peakValleyName{  
  margin:3px 20px;
  padding:3px;
  font-size:14px;
  line-height:24px;
  }
.peakValleySub{
  margin:30px 20px;
}
.buttonIcon{
  margin:5px 10px;
}
.buttonAddSub{
  margin:3px 10px;
  width: 100px;
  font-size:14px;
  background-color: rgb(145, 65, 196);
}
.selectEnable{
  margin:3px 10px;
  width: 100px;
  font-size:14px;
}
</style>