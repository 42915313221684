<!--能源模块--条件组件-->
<template>
  <div v-loading="frmloading" :element-loading-text="$t('alarm.a0')">
    <a-form-model 
      :model="form" 
      :rules="rules"
      :label-col="formItemLayout.labelCol" 
      :wrapper-col="formItemLayout.wrapperCol"  >
          
      <a-form-model-item  ref="name" prop="name">
          <span slot="label">
            {{label}}&nbsp;
            <a-tooltip :title='tooltip'>
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input 
              v-model="name"
              @blur="
              () => {
                  $refs.name.onFieldBlur();
              }
              "
              :placeholder="inputLabel"
              @input="changeName"
          />
      </a-form-model-item>                  
    </a-form-model>
  </div>       
</template>

<script>

export default {
  components: {
  },
  props: {
    value:String,
    label:String,   
    type:Number, 
    isAdd:Boolean,
  },
  data() {
      let tooltip=this.$t("energy.setting.enePeakValleySet.stop")+' [ ` ~ ! @ # $ % ^ & *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】';
      let inputLabel=this.$t("energy.setting.enePeakValleySet.inputLabel");      
      let name="";
      switch(this.type){
        case 0:
          if(this.isAdd)
            name= this.$t("energy.setting.enePeakValleySet.groupName");
          break;
        case 1:
          if(this.isAdd)
            name= this.$t("energy.setting.enePeakValleySet.subGroupName");
          else
            name = this.$t("energy.setting.enePeakValleySet.groupName");
          break;
        case 2:
          if(this.isAdd)
            name= this.$t("energy.setting.enePeakValleySet.periodOfTimeName");
          else
            name = this.$t("energy.setting.enePeakValleySet.subGroupName");
          break;
      }      
      inputLabel = inputLabel.replace('${label}',name);
      let lenLabel=this.$t("energy.setting.enePeakValleySet.lenLabel").replace('${label}',name).replace('${min}',1).replace('${max}',30);
      let spLabel=this.$t("energy.setting.enePeakValleySet.spLabel").replace('${label}',name);
      let normalName=(rule, value, callback) => {
            let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
            if(regEn.test(value)) {
                callback(new Error(spLabel));
            } else {
                callback();
            }
          };
    return {   
      name:"",
      form:{},   
      tooltip:tooltip,
      inputLabel:inputLabel,
      lenLabel:lenLabel,
      spLabel:spLabel,
      frmloading:false,
      formItemLayout:{
        labelCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      },
      rules: {
        name: [
            { required: true, message: inputLabel, trigger: 'blur' },
            { min: 1, max: 30, message: lenLabel, trigger: 'blur' },
            { validator:normalName, trigger: 'blur' },
        ]
      },
    };    
  },
  methods: {    
    changeName(){
      this.$emit("input", this.name);
    },
    
  },
  computed: {
    pluginsDataChange () {
      const dataStr=JSON.stringify(this.data);   
      // this.linkageConditionEdit = JSON.parse(dataStr);
      // console.log("subsection computed data:",this.data);    
      // this.name=this.data.name;     
      // this.title=this.data.title;  
      // console.log("subsection computed form:",this.form);
      // this.getStatusNameList();
      // return dataStr;
    }, 
       

  },
  watch:{
    value(val){
      this.name = val;
    },
    pluginsDataChange: function (val){
      // this.form = JSON.parse(val);
      // console.log("subsection watch form:",this.form);
    },
  }
};
</script>

<style scoped>
</style>