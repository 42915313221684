<!--能源模块--峰谷分组-->
<template>
  <div id="peakValleySub">
    <div class="header">
        <a-icon class="icon" type="caret-right" :rotate="isActive ? 90 : 0" />              
        <span class="text">{{ form.name }}</span>
        <!--添加图标-->
        <a-button  class="buttonIcon" style="color: #7682ce;" shape="circle" icon="plus-circle" size="small"  @click="addClick" />
        <!--修改图标-->
        <a-button  class="buttonIcon" shape="circle" icon="edit" size="small"  @click="editClick"  />
        <!--删除图标-->
        <a-button  class="buttonIcon" shape="circle" icon="delete" size="small" @click="deleteClick"  />
    </div>
    <div class="content">
        <subsection v-for="(item, index) in form.children" :key="index" :data="item" :options="options" @savePeriodOfTime="savePeriodOfTime" @deletePeriodOfTime="deletePeriodOfTime"></subsection>
        <a-button class="buttonSet" type="primary" @click="saveAllPeriodOfTime">
          保存
        </a-button>
    </div>  
    
    <div>
        <a-modal v-model="visible" :title="title" @ok="handleOk" :width='600'>
          <addEditModal :label="addEditModalName" v-model="name" :type="type" :isAdd="isAdd"></addEditModal>
        </a-modal>    
        <a-modal v-model="visibleGroup" :title="titleGroup" @ok="handleOkGroup" :width='400'>
          <addGroupModal v-model="form"></addGroupModal>
        </a-modal>      
    </div> 
  </div>
</template>

<script>
import subsection from './subsection.vue';
import addEditModal from './addEditModal.vue';
import addGroupModal from "./addGroupModal.vue"
import moment from "moment";

export default {
  components: {
    "subsection":subsection,
    "addEditModal":addEditModal,
    'addGroupModal':addGroupModal,
  },
  props: {
    data:{},
  },
  data() {      
    return {      
      isActive:false,
      visible:false,
      visibleGroup:false,
      titleGroup:"分组编辑",
      title:this.$t("energy.setting.enePeakValleySet.title"),      
      form:{
            id:0,//ID
            name: "",//名称
            siteId: this.$route.query.id,  //站点
            periodOfTime:"",//时间段                      
            type: 1,//类型（0：峰谷；1：分组；2：时间段）
            parentId:0,//上级
            isEnable:false,//是否启用
            isPermanent:false, //是否永久（不可删除）             
            lastUpdated:new moment().valueOf(),//最后更新时间 
            children:[],
        },  
      formAdd:{}, 
      addEditModalName:this.$t("energy.setting.enePeakValleySet.periodOfTimeName"),
      type:2,
      isAdd:false, 
      name:"",
      optionsSrc:[
        {key:1,value:"0:00~1:00",title:"0:00~1:00"},
        {key:2,value:"1:00~2:00",title:"1:00~2:00"},
        {key:3,value:"2:00~3:00",title:"2:00~3:00"},
        {key:4,value:"3:00~4:00",title:"3:00~4:00"},
        {key:5,value:"4:00~5:00",title:"4:00~5:00"},
        {key:6,value:"5:00~6:00",title:"5:00~6:00"},
        {key:7,value:"6:00~7:00",title:"6:00~7:00"},
        {key:8,value:"7:00~8:00",title:"7:00~8:00"},
        {key:9,value:"8:00~9:00",title:"8:00~9:00"},
        {key:10,value:"9:00~10:00",title:"9:00~10:00"},
        {key:11,value:"10:00~11:00",title:"10:00~11:00"},
        {key:12,value:"11:00~12:00",title:"11:00~12:00"},
        {key:13,value:"12:00~13:00",title:"12:00~13:00"},
        {key:14,value:"13:00~14:00",title:"13:00~14:00"},
        {key:15,value:"14:00~15:00",title:"14:00~15:00"},
        {key:16,value:"15:00~16:00",title:"15:00~16:00"},
        {key:17,value:"16:00~17:00",title:"16:00~17:00"},
        {key:18,value:"17:00~18:00",title:"17:00~18:00"},
        {key:19,value:"18:00~19:00",title:"18:00~19:00"},
        {key:20,value:"19:00~20:00",title:"19:00~20:00"},
        {key:21,value:"20:00~21:00",title:"20:00~21:00"},
        {key:22,value:"21:00~22:00",title:"21:00~22:00"},
        {key:23,value:"22:00~23:00",title:"22:00~23:00"},
        {key:24,value:"23:00~24:00",title:"23:00~24:00"},
      ],
      options:[],
    }
  },  
  methods: {   
    //过滤已被选时间段
    Optionfilter(){
      if(this.form.children==null||this.form.children.length==0)
        return;
      let periodOfTime = "";      
      this.options = [...this.optionsSrc];
      for(let j=0;j<this.form.children.length;j++){
        periodOfTime = this.form.children[j].periodOfTime;
        if(periodOfTime!=null && periodOfTime!=""){
          let selecteds = periodOfTime.split(",");
          if(selecteds!=null){
            for (let i = 0; i < selecteds.length; i++) {
              this.options = this.options.filter((item) => {
                return item.value!== selecteds[i];
              });
            }
          }
        }
      }
    },
    //保存所有时间段
    saveAllPeriodOfTime(){
      //特殊处理，取未分段所有时间段      
      if(this.form.children==null||this.form.children.length==0)
        return;

      let dataStr=JSON.stringify(this.form.children);              
      let allPeriodOfTime = JSON.parse(dataStr);

      console.log("peakValleySub saveAllPeriodOfTime1 par",allPeriodOfTime);      
      for(let j=0;j<allPeriodOfTime.length;j++){
        if(allPeriodOfTime[j].isPermanent){//未分段时间段
          if(this.options!=null&&this.options.length>0){
            allPeriodOfTime[j].periodOfTime=this.options[0].value;
            for(let i=1;i<this.options.length;i++){
              allPeriodOfTime[j].periodOfTime+=","+this.options[i].value;
            }
          }
          break;
        }
      }
      this.$emit("saveAllPeriodOfTime", allPeriodOfTime); 
    },
    //保存时间段
    savePeriodOfTime(e){
      this.$emit("savePeriodOfTime", e);      
    },
    //删除时间段
    deletePeriodOfTime(e){
      if(e.id==0){
        this.form.children = this.form.children.filter((item) => {
          return item.name!== e.name&&item.id!==0;
        });        
      }
      else
        this.$emit("deletePeriodOfTime", e);      
    },
    handleOk(){
      if(this.isAdd){//添加
        this.formAdd.name=this.name;
        this.$emit("addSub", this.formAdd); 
        this.visible=false;
      }
      else{
        // this.form.name=this.name;
        this.$emit("updateSub", this.form);
      }
    },
    //添加分组
    handleOkGroup(e){
      this.$emit("updateSub", this.form);
      this.visibleGroup=false;
    },
    addClick(e) {      
      this.formAdd={
            id:0,//数据库记录ID，若设置为0，则为新增
            name: "",//名称
            siteId: this.$route.query.id,  //站点
            periodOfTime:"",//时间段                      
            type: 2,//类型（0：峰谷；1：分组；2：时间段）
            parentId:this.form.id,//上级
            isEnable:false,//是否启用
            isPermanent:false, //是否永久（不可删除）             
            lastUpdated:new moment().valueOf(),//最后更新时间 
            color:"#c3c3c3"
        };       
      this.isAdd =true; 
      this.name=""; 
      this.type=2;      
      this.addEditModalName=this.$t("energy.setting.enePeakValleySet.periodOfTimeName");
      this.visible = true;     
    }, 
    editClick(e){
      this.visibleGroup = true; 
    },
    deleteClick(e){
      this.$emit("deleteSub", this.form);
    },
    
  },
  computed: {
    pluginsDataChange () {
      const dataStr=JSON.stringify(this.data);              
      this.form=this.data;
      console.log("peakValleySub pluginsDataChange form",this.form);
      //筛选时间段
      this.Optionfilter();
      let data = JSON.parse(dataStr);
      return dataStr;
    }


  },
  watch:{
    pluginsDataChange: function (val){
      // this.form = JSON.parse(val);
      // console.log("peakValleySub watch form:",this.form);
    },
  }
};
</script>

<style scoped>
#peakValleySub {
  /* display: flex; */
  /* flex-direction:  column; */
  /* justify-content: left; */  
  margin: 10px 10px;
}
.header {
  margin: 10px 0px;
}
.content{
  widows: 100%;
  margin-left: 50px;
  margin-top: 10px;
}
.icon{
  margin: 0px 10px;
}
.text{
  font-size: 14px;
  margin: 0px 10px;
}
.buttonSet{
  /* 相对父级定位&#xff0c;处于父级的右下角&#xff0c;距离右边30px、底部50px的位置 */
  position: relative;
  left: 1045px;
  background-color: rgb(145, 65, 196);
}
</style>